import React, { useCallback, useRef, useState } from 'react';
import { Row, Col, Badge } from 'reactstrap';

import {
  Intl, ModalInfo, Button, ConfirmationModal,
} from 'components';
import moment from 'moment';
import { connect } from 'react-redux';

import { formatPhone, handlePromise } from 'utils';
import GuestService from 'api/Guest';
import SettingsActions from 'store/reducers/Settings';
import RejectedModal from './rejected';

const GuestDetail = React.memo( ( {
  data, open, onClose, onReloadData, toggleLoading, toggleErrorAlert, toggleInfoAlert,
  isOwner,
} ) => {
  const [confirmationRejectedModalOpened, setConfirmationRejectedModalOpened] = useState( false );
  const [confirmationModalOpened, setConfirmationModalOpened] = useState( false );
  const statusToChange = useRef( null );

  const supportArrayValidation = ['DNI_español', 'Pasaporte_español'];

  const needSupportDoc = ( doc = '' ) => {
    const isNeccesary = supportArrayValidation.find( ( item ) => item === doc );
    return Boolean( isNeccesary );
  };

  const openConfirmationModal = useCallback( ( status ) => {
    statusToChange.current = status;
    setConfirmationModalOpened( true );
  }, [] );

  const closeConfirmationModal = useCallback( () => {
    setConfirmationModalOpened( false );
  }, [] );

  const openRejectedConfirmationModal = useCallback( ( status ) => {
    statusToChange.current = status;
    setConfirmationRejectedModalOpened( true );
  }, [] );

  const closeRejectedConfirmationModal = useCallback( () => {
    setConfirmationRejectedModalOpened( false );
  }, [] );

  const changeTravelReportStatus = useCallback( async ( dataToSend ) => {
    toggleLoading( true );
    const formattedData = {
      ...( dataToSend || {} ),
      travelReportStatus: statusToChange.current,
    };

    const [error, response] = await handlePromise(
      GuestService.updateGuest( data.id, formattedData ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( error );

    closeConfirmationModal();
    toggleInfoAlert( 'dataSaved' );
    setTimeout( () => {
      onReloadData();
      onClose();
    }, 100 );
  }, [data, onClose, onReloadData, closeConfirmationModal,
    toggleLoading, toggleInfoAlert, toggleErrorAlert] );

  return (
    <ModalInfo
      title="travelReport"
      open={open}
      onClose={onClose}
      translateTitle
    >

      <Row className="travel-report">
        <Col md={6}>
          <div className="info-column images">
            {!!data.frontalId && <img src={data.frontalId} width="100%" alt="front" />}
            {!!data.reverseId && <img src={data.reverseId} width="100%" alt="reverse" />}
            {!!data.selfie && <img src={data.selfie} width="100%" alt="selfie" />}
          </div>
        </Col>
        <Col md={6}>
          <div className="actions">
            {data.travelReportStatus === 'PENDING_VALIDATION' && (
            <Row>
              <Col md={6}>
                <Button
                  color="danger"
                  icon="ni ni-fat-remove"
                  title="reject"
                  extraClass="btn-block uppercase"
                  onClick={() => openRejectedConfirmationModal( 'REJECTED' )}
                />
              </Col>
              <Col md={6}>
                <Button
                  color="success"
                  icon="ni ni-check-bold"
                  title="approve"
                  extraClass="btn-block uppercase"
                  onClick={() => openConfirmationModal( 'APPROVED' )}
                />
              </Col>
            </Row>
            )}
          </div>
          <div className="info-column traveler-info">
            <Row>
              <Col className="info-field"><Intl id="status" /></Col>
              <Col>
                {data.travelReportStatus === 'PENDING' && (
                <Badge color="warning" className="px-2"><Intl id={data.travelReportStatus} /></Badge>
                )}
                {data.travelReportStatus === 'PENDING_VALIDATION' && (
                <Badge color="warning" className="px-2"><Intl id={data.travelReportStatus} /></Badge>
                )}
                {data.travelReportStatus === 'APPROVED' && (
                <Badge color="success" className="px-2"><Intl id={data.travelReportStatus} /></Badge>
                )}
                {data.travelReportStatus === 'REJECTED' && (
                <Badge color="danger" className="px-2"><Intl id={data.travelReportStatus} /></Badge>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="name" /></Col>
              <Col>{data.name}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="firstLastname" /></Col>
              <Col>{data.firstLastname || ''}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="secondLastname" /></Col>
              <Col>{data.secondLastname || ''}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="birthdate" /></Col>
              <Col>{moment.utc( data.birthdate ).format( 'DD/MM/YYYY' )}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="sex" /></Col>
              <Col>{data.sex === 'M' ? <Intl id="male" /> : <Intl id="female" /> }</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="nationality" /></Col>
              <Col className="uppercase">{data.nationality}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="locality" /></Col>
              <Col className="uppercase">{data.locality}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="address" /></Col>
              <Col className="uppercase">{data.address}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="postalCode" /></Col>
              <Col className="uppercase">{data.postalCode}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="phone" /></Col>
              <Col className="uppercase">{formatPhone( data.phone )}</Col>
            </Row>
            {!isOwner ? (
              <Row>
                <Col className="info-field"><Intl id="relation_with_owner" /></Col>
                <Col className="uppercase">{data.relationshipWithOwner}</Col>
              </Row>
            ) : null}
            <hr />
            <Row>
              <Col className="info-field"><Intl id="documentType" /></Col>
              <Col>{data.documentType}</Col>
            </Row>
            <Row>
              <Col className="info-field"><Intl id="documentNumber" /></Col>
              <Col>{data.documentNumber}</Col>
            </Row>
            {needSupportDoc( data.documentType ) ? (
              <Row>
                <Col className="info-field"><Intl id="supportDocumentNumber" /></Col>
                <Col>{data.supportDocumentNumber}</Col>
              </Row>
            ) : null}
            <Row>
              <Col className="info-field"><Intl id="expeditionDate" /></Col>
              <Col>{moment.utc( data.expeditionDate ).format( 'DD/MM/YYYY' )}</Col>
            </Row>
            <Row><Col className="info-field"><Intl id="signature" /></Col></Row>
            {!!data.signature && <img src={data.signature} width="100%" alt="signature" />}
          </div>
        </Col>
      </Row>

      <ConfirmationModal
        open={confirmationModalOpened}
        title={statusToChange.current === 'APPROVED' ? 'confirmTravelReportApprove' : 'confirmTravelReportReject'}
        onConfirm={changeTravelReportStatus}
        onClose={closeConfirmationModal}
      />
      <RejectedModal
        open={confirmationRejectedModalOpened}
        onSubmit={changeTravelReportStatus}
        onClose={closeRejectedConfirmationModal}
      />
    </ModalInfo>
  );
} );

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( GuestDetail );
